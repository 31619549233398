import "../App.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
// import Form from "../Components/Form";
import { Button, Grid, List, ListItemText, Box } from "@mui/material";
import formulaire_hc from "../Assets/formulaire_hc.pdf";
import formulaire_hdj from "../Assets/formulaire_hdj.pdf";

function ContactAutres() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          "flex-direction": "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <div className="position-relative">
          <section className="section section-lg section-shaped">
            <div className="py-lg-md d-flex">
              <div className="col px-0">
                <div>
                  <div lg="6">
                    <div className="display-1">Formulaire de contact</div>
                    <div className="display-1">DEMANDER SON ADMISSION</div>
                    <Box width="75%" m="auto" pb="50px" pt="50px">
                      <Button variant="contained" href={formulaire_hc} download>
                        Téléchargez notre formulaire d'admission en temps
                        complet
                      </Button>
                      <Button
                        variant="contained"
                        href={formulaire_hdj}
                        color="secondary"
                        download
                      >
                        Téléchargez notre formulaire d'admission en temps
                        partiel
                      </Button>
                    </Box>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      justifyContent="center"
                      columns={{ xs: 6, sm: 6, md: 12 }}
                    >
                      {/* <Form /> */}
                      <List>
                        <ListItemText className="display-1">
                          Clinique Montfleuri Saint-Roch
                        </ListItemText>
                        <ListItemText>
                          160 Route des Camoins - 13011 Marseille
                        </ListItemText>
                        <ListItemText>Tel : 04 91 43 40 13 </ListItemText>
                        <ListItemText>
                          admissions@saintroch-montfleuri.fr
                        </ListItemText>
                      </List>
                      <Grid item xs={6}>
                        <iframe
                          title="map"
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12362.795391799416!2d5.499352060642844!3d43.29785432763659!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9bc46a79e9c75%3A0x44dfcc2bba2dcd56!2sClinique%20Psychiatrique%20Saint%20Roch!5e0!3m2!1sfr!2sfr!4v1655455108266!5m2!1sfr!2sfr"
                          width="100%"
                          height="100%"
                          style={{ border: 0 }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <List>
                          <ListItemText className="display-2">
                            Clinique Montfleuri
                          </ListItemText>
                          <ListItemText>
                            Saint Roch 160 Route des Camoins 13011 Marseille
                          </ListItemText>
                          <ListItemText>Tel : 04 91 43 01 20 </ListItemText>
                        </List>
                      </Grid> */}
                    </Grid>

                    <div className="display-1">NOUS REJOINDRE</div>
                    <List>
                        <ListItemText className="display-1">
                          rh@saintroch-montfleuri.fr
                        </ListItemText>                              
                         </List>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ContactAutres;
